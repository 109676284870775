import router from "@/router/index.js";

export default {
  handlePayResponseKo(data, orderId) {
    var cart = data.data;

    if (!orderId) {
      orderId = cart && cart.order ? cart.order.orderId : null;
    }
    router.push("/payment-error/" + orderId);
  },
  payment(paymentUrl, paymentParameters, method, orderId) {
    var ref;
    switch (method) {
      case "get":
        if (window.cordova && window.cordova.InAppBrowser) {
          ref = window.cordova.InAppBrowser.open(
            paymentUrl,
            "_blank",
            "location=no,toolbar=no,zoom=no,hardwareback=no,enableViewportScale=yes"
          );
          if (ref) {
            ref.addEventListener("loadstart", function(args) {
              var url = args.url;
              if (url.indexOf("/payment-completed") > 0) {
                ref.close();
                router.push("/cart/payment-completed/" + orderId);
              }
              if (url.indexOf("/payment-error") > 0) {
                ref.close();
                router.push("/cart/payment-error/" + orderId);
              }
            });
            ref.addEventListener("exit", function(args) {
              var url = args.url;
              if (url.indexOf("/payment-completed") < 0) {
                router.push("/cart/payment-completed/" + orderId);
              }
            });
          }
        } else {
          window.location = paymentUrl;
        }
        break;
      case "post":
        // POST FA UNA SUBMIT CREANDO UNA FORM DINAMICA
        // DIPENDE DAL GATEWAY DI PAGAMENTO (Es. sotto Nexi.)
        // var pageContent = [];
        // var form = document.createElement("form");
        // form.setAttribute("method", method);
        // form.setAttribute("action", paymentUrl);

        // // Page Content for InAppBrowser
        // pageContent.push(
        //   '<html><head></head><body><form id="paymentForm" action="' +
        //     paymentUrl +
        //     '" method="' +
        //     method +
        //     '">'
        // );

        // for (var key in paymentParameters) {
        //   // eslint-disable-next-line no-prototype-builtins
        //   if (paymentParameters.hasOwnProperty(key)) {
        //     if (key != "redirectUrl") {
        //       var hiddenField = document.createElement("input");
        //       hiddenField.setAttribute("type", "hidden");
        //       hiddenField.setAttribute("name", key);
        //       hiddenField.setAttribute("value", paymentParameters[key]);

        //       pageContent.push(
        //         '<input type="hidden" name="' +
        //           key +
        //           '" value="' +
        //           paymentParameters[key] +
        //           '">'
        //       );

        //       form.appendChild(hiddenField);
        //     }
        //   }
        // }

        // pageContent.push(
        //   '</form> <script type="text/javascript">document.getElementById("paymentForm").submit();</script></body></html>'
        // );
        // var pageContentUrl = pageContent.join("");

        // document.body.appendChild(form);
        // if (window.cordova && window.cordova.InAppBrowser) {
        //   ref = window.cordova.InAppBrowser.open(
        //     "data:text/html;base64," + btoa(pageContentUrl),
        //     "_blank",
        //     "location=no,toolbar=yes,zoom=no,hardwareback=no,enableViewportScale=yes"
        //   );
        //   if (ref) {
        //     ref.addEventListener("loadstart", function(args) {
        //       var url = args.url;
        //       if (url.indexOf("/payment-completed") > 0) {
        //         ref.close();
        //         router.push("/payment-completed/" + orderId);
        //       }
        //       if (url.indexOf("/payment-error") > 0) {
        //         ref.close();
        //         router.push("/payment-error/" + orderId);
        //       }
        //     });
        //   }
        // } else {
        //   form.submit();
        // }
        break;
    }
  }
};
